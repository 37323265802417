/* eslint-disable jsx-quotes */
import React from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import {
  Welcometo,
  ChevronRight,
  Footericon1,
  Footericon2,
  Footericon3,
  Footericon4,
  Footericon5,
} from '../../images';

function FooterInfoSection() {
  return (
    <>
      <div className="bg-white rounded-lg mt-20 mb-4">
        <div className="grid grid-cols-12 pb-6 px-6 md:px-8 gap-6">
          <div className="col-span-12 md:col-span-5 relative">
            <div className="leading-none text-center -mt-6 lg:-mt-10">
              <Image src={Welcometo} alt="Welcome to BLeon77" width={464} height={386} placeholder='empty' />
            </div>
            <div
              style={{
                position: 'absolute',
                width: 58,
                height: 58,
                background: '#28AE60',
                border: 'solid 6px white',
                top: '46%',
                right: -15,
              }}
              className="rounded-full hidden lg:flex items-center justify-center"
            >
              <div
                className="leading-none"
                style={{
                  width: 8,
                  filter:
                    'invert(100%) sepia(100%) saturate(1%) hue-rotate(328deg) brightness(112%) contrast(101%)',
                }}
              >
                <Image src={ChevronRight} alt="" width={8} height={12} placeholder='empty' />
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 flex items-center">
            <div className="md:ml-4">
              <h1 className="text-xl md:text-2xl font-bold">
                Welcome
                {' '}
                <span className="text-xl md:text-2xl font-bold capitalize text-primary">
                  to Bleon77
                </span>
              </h1>
              <p className="text-2xs mb-4">
                Step into the exciting world of Bleon77 , where the fun never
                stops! We're here to bring you an amazing selection of games, so
                get ready to have a blast.
              </p>
              <p className="text-2xs mb-4">
                With over 1000 of slot machines, live casino, and strategy card
                games like Andar Bahar and Teen Patti, we offer something for
                everyone. And if you're a sports enthusiast, look no further -
                we've got you covered with Cricket, Soccer, and more.
              </p>
              <p className="text-2xs">
                At Bleon77, your safety and enjoyment are our top priorities.
                Our website provides a secure environment for you to play and
                have a fantastic time.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="grid md:grid-cols-12 lg:grid-cols-10 gap-4">
          <div className="sm:col-span-2 md:col-span-4 lg:col-span-2">
            <div className="bg-white rounded-lg pt-3 px-4 text-center flex items-center flex-col h-40 md:h-44 lg:h-48">
              <div
                style={{
                  width: 50,
                  height: 50,
                  background: '#EDEDED',
                }}
                className="rounded-full flex items-center justify-center p-3 mb-3"
              >
                <Image src={Footericon2} width={26} height={24} alt="Customer Support" placeholder='empty' />
              </div>
              <h2 className="font-medium text-xs">
                <span className="text-primary">24/7</span>
                {' '}
                Customer Support Team
                at your service.
              </h2>
            </div>
          </div>
          <div className="sm:col-span-2 md:col-span-4 lg:col-span-2">
            <div className="bg-white rounded-lg pt-3 px-4 text-center flex items-center flex-col h-40 md:h-44 lg:h-48">
              <div
                style={{
                  width: 50,
                  height: 50,
                  background: '#EDEDED',
                }}
                className="rounded-full flex items-center justify-center p-3 mb-3"
              >
                <Image src={Footericon1} alt="Fast & efficient deposit withdrawal" width={26} height={27} placeholder='empty' />
              </div>
              <h3 className="font-medium text-xs">
                Fast & efficient deposit withdrawal process of your winning.
              </h3>
            </div>
          </div>
          <div className="sm:col-span-2 md:col-span-4 lg:col-span-2">
            <div className="bg-white rounded-lg pt-3 px-4 text-center flex items-center flex-col h-40 md:h-44 lg:h-48">
              <div
                style={{
                  width: 50,
                  height: 50,
                  background: '#EDEDED',
                }}
                className="rounded-full flex items-center justify-center p-4 mb-3"
              >
                <Image src={Footericon3} alt="No need to download any apps" width={18} height={26} placeholder='empty' />
              </div>
              <h3 className="font-medium text-xs">
                No need to download any apps, simply browse and play with just a
                tap of your finger.
              </h3>
            </div>
          </div>
          <div className="sm:col-span-2 md:col-span-4 lg:col-span-2">
            <div className="bg-white rounded-lg pt-3 px-4 text-center flex items-center flex-col h-40 md:h-44 lg:h-48">
              <div
                style={{
                  width: 50,
                  height: 50,
                  background: '#EDEDED',
                }}
                className="rounded-full flex items-center justify-center p-3 mb-3"
              >
                <Image src={Footericon4} alt="Quick and easy account registration" width={26} height={26} placeholder='empty' />
              </div>
              <h3 className="font-medium text-xs">
                Quick and easy account registration in just 2 minutes
              </h3>
            </div>
          </div>
          <div className="sm:col-span-2 md:col-span-4 lg:col-span-2">
            <div className="bg-white rounded-lg pt-3 px-4 text-center flex items-center flex-col h-40 md:h-44 lg:h-48">
              <div
                style={{
                  width: 50,
                  height: 50,
                  background: '#EDEDED',
                }}
                className="rounded-full flex items-center justify-center p-3 mb-3"
              >
                <Image src={Footericon5} alt="Extensive selection of casino games" width={26} height={26} placeholder='empty' />
              </div>
              <h3 className="font-medium text-xs">
                Extensive selection of casino games, slots, sports betting, and
                more.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterInfoSection;
