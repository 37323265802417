import {
  Matchbanner1,
  Matchbanner2,
  Matchbanner3,
  Matchbanner4,

} from '../../images';

export const homePageRow2BannerList = [
  {
    image: Matchbanner1,
    altText: 'Pro Kabaddi League',
  },
  {
    image: Matchbanner2,
    altText: 'La Liga',
  },
  {
    image: Matchbanner3,
    altText: 'Bundesliga',
  },
  {
    image: Matchbanner4,
    altText: 'Australian Open',
  },
  // {
  //   image: Matchbanner1,
  //   altText: 'Pro Kabaddi League',
  // },
  // {
  //   image: Matchbanner2,
  //   altText: 'La Liga',
  // },
  // {
  //   image: Matchbanner3,
  //   altText: 'Bundesliga',
  // },
  // {
  //   image: Matchbanner4,
  //   altText: 'Australian Open',
  // },
];
