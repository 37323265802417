import { useState } from 'react';

const HomeFaqList = [
  // account
  {
    label: 'How do I create an account with BLeon77?',
    content:
      'Select "Join Now" in the main menu, and fill in the registration form. a 6 digit number is require to verify your phone number, complete the registration by simply click on the "Register" button.',
  },
  {
    label: 'How do I close my account?',
    content:
      'To close your account, the only available method is to directly contact Customer Support. We are more than happy to assist you in this process. You can reach out to us via email or through Live Chat, which is the fastest and most convenient option.',
  },
  {
    label: 'My deposit failed. What\'s wrong?',
    content:
      'We understand that sometimes deposits may encounter issues, and one of the common reasons could be related to the funding in your bank account. We kindly suggest verifying that your account has sufficient funds before making another deposit attempt. Should you require any further assistance, our dedicated customer service team is always available to provide you with the necessary support. Please feel free to reach out to us at any time.',
  },
  {
    label:
      'I have made a deposit to BLeon77. When will the money be available in my account?',
    content:
      'Processing time will depend on your chosen method. Deposit via automated payment solutions are processed instantly while Local Bank Transfer takes up to 3 working days. For more information, kindly view our Payment Options page.',
  },
  //   withdrawal
  {
    label:
      'I have made a withdrawal from Bleon77. When will I receive my money?',
    content:
      'Please wait while the withdraw is in progress. Withdrawal via UPI may take up to 15 minutes to process.',
  },
  {
    label: 'Where to find bonuses?',
    content:
      'Under the "Promotions" section in your account, you can discover seasonal promotions and various types of bonuses, along with their respective terms and conditions.',
  },
];
const HomeFaqList1 = [
  {
    label: 'Cannot open an account. What\'s wrong?',
    content:
      'Please ensure that all fields are accurately filled in, and the Username are correct. Alternatively, you may click on "Login" and select "Forgot Password," or you can reach out to us directly for assistance.',
  },
  // deposit
  {
    label: 'How do I deposit money to Bleon77',
    content:
      'Click on your balance in the top right menu, choose the deposit method, and enter the desired amount. Use only your own payment methods.',
  },
  {
    label:
      'I made a deposit but the funds were not credited to my account. What should I do?',
    content:
      'No need to worry. Before reaching out to us, kindly ensure that the funds were deducted from your account. If the deduction occurred, please provide us with a copy of the transaction for further investigation on our end. Our team will be glad to assist you promptly.',
  },
  //   withdrawal
  {
    label: 'How do I withdraw money from BLeon77?',
    content: (
      <ul className='list-disc ml-4'>
        <li>Want to withdraw funds? Here's how to do it:</li>
        <li>Log in and click on your balance at the top right corner</li>
        <li>Click ’Withdraw’.</li>
        <li>Select the payment method you wish to withdraw with.</li>
        <li>
          Enter the amount you wish to withdraw, then click 'Withdraw'.
        </li>
        <li>The minimum withdrawal amount is INR 1,000.</li>
      </ul>
    ),
  },
  // bonus
  {
    label: 'What is a bonus?',
    content:
      'An online casino bonus is a special offer or incentive given to players, which may include welcome bonuses, free spins, Rebate, and other perks. These bonuses are intended to reward players and enhance their overall gaming experience.',
  },
  {
    label: 'How does wagering works',
    content: (
      <div>
        <p>
          If it’s a Sports Welcome Bonus, you can use these funds only for
          sports betting. The wagering requirements for welcome bonuses
          are 19x of the deposit and bonus amount.
        </p>
        <br />
        <p>
          All available bonuses for regular members are placed in our
          Promo section. Check out the terms on how to use bonus in
          BLeon77 before claiming any of it
        </p>
      </div>
    ),
  },
];

function HomeFaq() {
  const [openId, setOpenId] = useState(0);

  return (
    <div>
      <h3 className='text-base font-semibold mb-2'>FAQ</h3>
      <div className='md:grid grid-cols-2 gap-x-2'>
        <div>
          {HomeFaqList.map((key, index) => (
            <div className='mb-2' key={index}>
              <div>
                <div
                  onClick={() => {
                    if (openId === `left-${index}`) {
                      setOpenId(-1);
                    } else {
                      setOpenId(`left-${index}`);
                    }
                  }}
                  className={`menu-faq bg-white rounded p-3 flex items-center cursor-pointer justify-between ${openId === `left-${index}` && 'menu-faq-active'}`}
                >
                  <p className='font-normal'>
                    {key.label}
                  </p>
                </div>
                {
                  openId === `left-${index}`
                  && (
                    <div
                      className='bg-transparent homeFaqContent mx-2 mb-4'
                      id={key.label}
                    >
                      <p className='text-xs'>{key.content}</p>
                    </div>
                  )
                }
              </div>
            </div>
          ))}
        </div>
        <div>
          {HomeFaqList1.map((key, index) => (
            <div className='mb-2' key={index}>
              <div>
                <div
                  className={`menu-faq bg-white rounded p-3 flex items-center cursor-pointer justify-between ${openId === `right-${index}` && 'menu-faq-active'}`}
                  onClick={() => {
                    if (openId === `right-${index}`) {
                      setOpenId(-1);
                    } else {
                      setOpenId(`right-${index}`);
                    }
                  }}
                >
                  <p className='font-normal'>
                    {key.label}
                  </p>
                </div>
                {
                  openId === `right-${index}`
                  && (
                    <div
                      className='bg-transparent homeFaqContent mx-2 mb-4'
                      id={key.label}
                    >
                      <p className='text-xs'>{key.content}</p>
                    </div>
                  )
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomeFaq;
